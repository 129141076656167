<div class="grid">
  <div class="col-12">
    <div class="card p-4 flex flex-column h-full">
      <div class="text-2xl text-primary font-bold mb-3 flex align-items-center">
        <span class="p-badge text-white p-badge-no-gutter p-badge-lg mr-2"
          ><i class="pi pi-cog"></i
        ></span>
        Ρυθμίσεις Λογαριασμού
      </div>
      <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
      <div
        class="flex flex-row justify-content-start align-items-center flex-wrap gap-5"
      >
        <h6 class="text-900 font-bold text-lg text-center p-0 m-0">Συγχρονισμός ημερολογίου</h6>
        <div class="flex flex-row align-items-center gap-3">
          <img
            src="/assets/images/google-calendar-48x48.png"
            alt=""
            srcset=""
          />
          @if(syncedCalendar['google']==false){
          <h6>Google Calendar</h6>
          <i
            class="pi pi-sync font-bold cursor-pointer"
            (click)="syncCalendar('google')"
          ></i>
          }@else{
          <h6>Synced</h6>
          }
        </div>
        <!-- <div class="flex flex-row align-items-center gap-3">
        <img src="/assets/images/yahoo-calendar-48x48.png" alt="" srcset="" />
        @if(syncedCalendar['yahoo']==false){
        <h6>Yahoo Calendar</h6>
        <i
          class="pi pi-sync font-bold cursor-pointer"
          (click)="syncCalendar('yahoo')"
        ></i>
        }@else{
        <h6>Synced</h6>
        }
      </div>
      <div class="flex flex-row align-items-center gap-3">
        <img
          src="/assets/images/microsoft-calendar-48x48.png"
          alt=""
          srcset=""
        />@if(syncedCalendar['microsoft']==false){
        <h6>Microsoft Calendar</h6>
        <i
          class="pi pi-sync font-bold cursor-pointer"
          (click)="syncCalendar('microsoft')"
        ></i>
        }@else{
        <h6>Synced</h6>
        }
      </div>
      <div class="flex flex-row align-items-center gap-3">
        <img
          src="/assets/images/apple-calendar-48x48.png"
          alt=""
          srcset=""
        />@if(syncedCalendar['apple']==false){
        <h6>Apple Calendar</h6>
        <i
          class="pi pi-sync font-bold cursor-pointer"
          (click)="syncCalendar('apple')"
        ></i>
        }@else{
        <h6>Synced</h6>
        }
      </div> -->
      </div>
    </div>
  </div>
  <div class="col-12">
    <lib-personal-info [userData]="userData | async"></lib-personal-info>
  </div>
</div>
