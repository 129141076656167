import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import Talk from 'talkjs';
import { environment } from '@shared/environments';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  concatMap,
  delay,
  interval,
  Observable,
  retryWhen,
  startWith,
  switchMap,
  take,
  timer,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };
  private apiUrl = `${environment.api}baseAuthentication`;

  private currentUser!: Talk.User;
  user!: any;
  chatPopup!: Talk.Popup | undefined;
  talkJsJWTToken: any;
  unreadMessages: any;
  constructor(private auth: AuthService, private http: HttpClient) {
    this.getChatJWTToken();
    this.auth.currentUser$.subscribe((user: any) => {
      this.user = user;
    });
    // this.user = this.auth.currentUser
  }
  async createUser(applicationUser: any) {
    await Talk.ready;
    return new Talk.User({
      id: applicationUser.id,
      name: applicationUser.username,
      photoUrl:
        'https://gravatar.com/avatar/cf86990164dbd7ef3d0f95e81cafbe1e?s=400&d=mp&r=x',
      role: 'default',
    });
  }

  async createCurrentSession() {
    await Talk.ready;
    console.log('user', this.user);

    if (!this.user || !this.user.data) {
      throw new Error('User data is not available.');
    }
    const user = {
      id: this.user.data.userId,
      username: this.user.data.fullName,
      email: this.user.data.email,
      photoUrl:
        'https://gravatar.com/avatar/cf86990164dbd7ef3d0f95e81cafbe1e?s=400&d=mp&r=x',
      role: 'default',
    };
    this.currentUser = await this.createUser(user);

    const session = new Talk.Session({
      appId: environment.APP_ID,
      me: this.currentUser,
    });

    return session;
  }

  private async getOrCreateConversation(
    session: Talk.Session,
    otherApplicationUser: any
  ) {
    const otherUser = await this.createUser(otherApplicationUser);
    const conversation = session.getOrCreateConversation(
      Talk.oneOnOneId(this.currentUser, otherUser)
    );
    console.log('conv', otherApplicationUser);
    conversation.setParticipant(this.currentUser);
    conversation.setParticipant(otherUser);
    conversation.setAttributes({
      welcomeMessages: [
        `Επικοινωνήστε με τον χρήστη ${otherApplicationUser.username}`,
      ],
    });
    return conversation;
  }

  async createPopup(session: Talk.Session, otherUser?: any) {
    const conversation = await this.getOrCreateConversation(session, otherUser);
    if (this.chatPopup) {
      this.chatPopup.destroy(); // Close and clean up the current popup
      this.chatPopup = undefined; // Reset the chatPopup reference
    }
    // Create and show the popup
    this.chatPopup = session.createPopup(conversation, {
      keepOpen: false,
    });

    // this.chatPopup.mount({ show: true }); // Make sure popup is mounted and shown

    return this.chatPopup;
  }

  async createInbox(session: Talk.Session) {
    return session.createInbox({ showChatHeader: false });
  }

  startPolling(userId: string): Observable<any> {
    let requestInterval = 1000;
    let retryCount = 5;
    return timer(0, requestInterval).pipe(
      concatMap(() => this.getUserConversations(userId)),
      retryWhen((error$) =>
        error$.pipe(delay(requestInterval), take(retryCount))
      )
    );
  }

  // Fetch notifications for the given userId
  private getUserConversations(userId: string): Observable<any> {
    const url = `https://api.talkjs.com/v1/tNh5WSLo/users/${userId}/conversations`;
    const reqHeader = new HttpHeaders({
      Authorization: `Bearer ${this.talkJsJWTToken}`,
    });
    return this.http.get(url, {
      headers: reqHeader,
    });
  }
  getChatJWTToken() {
    interval(30000)
      .pipe(
        startWith(0),
        switchMap(() => this.http.get<any>(`${this.apiUrl}/chatjwt`))
      )
      .subscribe({
        next: (response) => {
          this.talkJsJWTToken = response.jwtToken;
        },
        error: (err) => {
          console.error('Error fetching token:', err);
        },
      });
  }
}
