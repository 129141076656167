import { Component, inject, OnInit } from '@angular/core';
import { PersonalInfoComponent } from './personal-info/personal-info.component';

import { AsyncPipe } from '@angular/common';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [PersonalInfoComponent, AsyncPipe],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css',
})
export class SettingsComponent implements OnInit {
  syncedCalendar: Record<string, boolean> = {
    google: false,
    yahoo: false,
    microsoft: false,
    apple: false,
  };

  allowedCalendars: ('google' | 'yahoo' | 'microsoft' | 'apple')[] = [
    'google',
    'yahoo',
    'microsoft',
    'apple',
  ];

  private authService = inject(AuthService);
  userData = this.authService.currentUser$;
  
  ngOnInit(): void {
    const storedCalendars = localStorage.getItem('calendar');
    if (storedCalendars) {
      const calendarArray = JSON.parse(storedCalendars) as string[];
      calendarArray.forEach((name) => {
        if (this.syncedCalendar[name] !== undefined) {
          this.syncedCalendar[name] = true;
        }
      });
    }
  }

  syncCalendar(calendarName: 'google' | 'yahoo' | 'microsoft' | 'apple'): void {
    if (!this.allowedCalendars.includes(calendarName)) {
      // console.error(`${calendarName} is not a recognized calendar.`);
      return;
    }

    this.syncedCalendar[calendarName] = true;

    let existing: string[] = [];
    if (localStorage.getItem('calendar')) {
      existing = JSON.parse(localStorage.getItem('calendar')!) as string[];
    }

    if (!existing.includes(calendarName)) {
      existing.push(calendarName);
      localStorage.setItem('calendar', JSON.stringify(existing));
    } else {
    }
  }
}
