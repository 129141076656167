import { DatePipe, NgClass } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TabViewModule } from 'primeng/tabview';

@Component({
  selector: 'lib-personal-info',
  standalone: true,
  imports: [DatePipe, NgClass, TabViewModule],
  templateUrl: './personal-info.component.html',
  styleUrl: './personal-info.component.css',
})
export class PersonalInfoComponent implements OnInit, OnChanges {
  @Input({ required: true }) userData: any;
  tabs: { title: string; content: any }[] = [];
  user = {
    personalInfo: [
      {
        label: 'Ονοματεπώνυμο',
        key: 'fullName',
        icon: 'pi pi-id-card',
      },
      {
        label: 'Ημ/νία Γέννησης',
        key: 'dateOfBirth',
        icon: 'pi pi-calendar',
      },
      {
        label: 'Φύλο',
        key: 'gender',
        icon: 'pi pi-venus',
      },
      {
        label: 'Εθνικότητα',
        key: 'enthinicity',
        icon: 'pi pi-globe',
      },
      {
        label: 'Οικογενειακή Κατάσταση',
        key: 'familyStatus',
        icon: 'pi pi-lock',
      },
    ],
    identifiers: [
      {
        label: 'Α.Μ.Κ.Α.',
        key: 'socialSecurityNumber',
        icon: 'pi pi-key',
      },
      {
        label: 'Α.Φ.Μ',
        key: 'afm',
        icon: 'pi pi-book',
      },
      {
        label: 'Α.Δ.Τ.',
        key: 'identityCardNumber',
        icon: 'pi pi-shield',
      },
    ],
    contractInfo: [
      {
        label: 'Κινητό',
        key: 'mobile',
        icon: 'pi pi-mobile',
      },
      {
        label: 'Σταθερό',
        key: 'telephone',
        icon: 'pi pi-phone',
      },
      {
        label: 'Email',
        key: 'email',
        icon: 'pi pi-envelope',
      },
      {
        label: 'Δήμος',
        key: 'municipality',
        icon: 'pi pi-building-columns',
      },
      {
        label: 'Οδός',
        key: 'address',
        icon: 'pi pi-directions',
      },
      {
        label: 'Τ.Κ',
        key: 'postCode',
        icon: 'pi pi-at',
      },
    ],
  };

  ngOnInit(): void {
    this.tabs = [
      { title: 'Προσωπικά Στοιχεία', content: this.user.personalInfo },
      { title: 'Αναγνωριστικά', content: this.user.identifiers },
      { title: 'Στοιχεία Επικοινωνίας', content: this.user.contractInfo },
    ];
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.userData.data['gender'] = 'Άντρας';
    this.userData.data['enthinicity'] = 'Έλληνας';
    this.userData.data['familyStatus'] = 'Άγαμος';
    this.userData.data['afm'] = '17898785';
    this.userData.data['email'] = 'gkourkoulis@covariance.gr';
    this.userData.data['municipality'] = 'Δήμος Αιγάλεω';
    this.userData.data['address'] = 'Έλλης 57';
    this.userData.data['postCode'] = '12243';
  }

  addDummyData(): void {}
}
